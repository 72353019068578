/** @format */

import { bindable } from 'aurelia-framework';
import poiService from 'services/api/areasService';

const NO_POI_ID = 0;

export class SelectSearchPoi {
  @bindable onSelectionChanged;
  @bindable selected;
  @bindable label;

  constructor() {
    this.poiService = poiService;
    this.pois = [
      {
        id: NO_POI_ID,
        name: 'None',
        default: true
      }
    ];
    this.label = null;

    this._onSelectionChanged = this._onSelectionChanged.bind(this);
  }

  attached() {
    this.loadData();
  }

  loadData() {
    this.poiService.get().then(pois => {
      pois.forEach(p => (p.selected = p.id === this.selected));
      this.pois = [].concat(this.pois, pois);
    });
  }

  _onSelectionChanged(poi) {
    let selected = poi || this.pois[0];

    this.selected = selected.id;
    if (this.onSelectionChanged) this.onSelectionChanged(selected);
  }
}
