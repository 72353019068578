/** @format */

import { bindable } from 'aurelia-framework';
import groupsService from 'services/api/groupsService';
import { TranslationService } from 'services/translationService';

export class SelectGroup {
  static inject() {
    return [TranslationService];
  }

  @bindable groupId;
  @bindable allowEmpty;
  @bindable onGroupSelect;
  @bindable label;
  @bindable disabled;

  constructor(_ts) {
    this.groupsService = groupsService;

    this.groupsList = [];
    this.groupId = null;
    this.allowEmpty = true;
    this.NO_GROUP_ITEM = { id: 0, name: _ts.getCap('no_group'), default: true };
    this.label = null;
    this.disabled = false;

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  get defaultGroup() {
    if (!this.allowEmpty) {
      return this.groupsList[0] || this.NO_GROUP_ITEM;
    }
    return this.NO_GROUP_ITEM;
  }

  attached() {
    this.loadGroups().then(() => {
      this.selectGroup(this.groupId);
    });
  }

  groupIdChanged(id, oldId) {
    if (id && id !== oldId) {
      this.selectGroup(id);
    }
  }

  allowEmptyChanged(value) {
    this.allowEmpty = !!value && value !== 'false';
  }

  loadGroups() {
    return this.groupsService.get().then(_groups => {
      if (this.allowEmpty) {
        _groups.unshift(this.NO_GROUP_ITEM);
      }
      this.groupsList = _groups;

      return _groups;
    });
  }

  selectGroup(id) {
    let _id = id;

    if (!id || !this.groupsList.find(x => x.id === id)) {
      _id = this.defaultGroup.id;
    }

    this.groupsList.forEach(g => {
      g.selected = g.id === _id;
    });
  }

  onSelectionChanged(selected) {
    selected = selected || this.defaultGroup;
    let group = selected;
    this.groupId = group.id;

    if (this.onGroupSelect) {
      this.onGroupSelect(group);
    }
  }
}
