import {
  inject,
  customElement,
  bindable
} from 'aurelia-framework';
import './search-input.scss';

@customElement('search-input')
@inject(Element)
export class SearchInput {
  @bindable value;
  @bindable cssClass;
  @bindable disabled;
  @bindable readonly;
  @bindable label;
  @bindable autocomplete;
  
  @bindable leadIcon;
  @bindable trailIcon;
  @bindable trailIconModes;

  @bindable inputElement;

  @bindable onClear;

  constructor(element) {
    this.element = element
    this.value = null;
    this.cssClass = '';
    this.disabled = null;
    this.readonly = null;
    this.label = 'search';

    this.trailIconModes = {
      search: {
        show: false,
        class: 'search-action',
        icon: 'fi-search',
        action: this.searchAction.bind(this)
      },
      close: {
        show: (this.disabled || this.readonly) ? false : true,
        class: 'clear-action',
        icon: 'fi-clear',
        action: this.closeAction.bind(this)
      }
    };
    this.trailIcon = this.trailIconModes.search || null;
    this.leadIcon = {
      show: true,
      class: '',
      icon: 'fi-search'
    }
  }

  onInputClick() {
    this.trailIcon.action();
  }

  valueChanged(value) {
    if(!this.trailIconModes) return;
    if (value && value !== '') {
      this.trailIcon = this.trailIconModes.close;
    } else {
      this.trailIcon = this.trailIconModes.search;
    }
  }

  searchAction() {}

  closeAction() {
    this.value = '';
    if (this.onClear) {
      this.onClear(this.value);
    }
  }

  changeElementStatus(event) {
    var eventDetails = new CustomEvent('element-status-changed', {
      detail: event.detail,
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }
}
