import { bindable, bindingMode} from 'aurelia-framework';

import './phone-input.scss';
export class PhoneInput {

  @bindable label;
  @bindable required;

  constructor() {
    this.activeLabel = false;
    this.label = 'phoneNumber';
  }

  @bindable({ defaultBindingMode: bindingMode.twoWay }) number;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) countryCode;

  onIndicativeChange(value) {
    if(value.length < 2) {
      this.countryCode = '';
    }
  }

  changeElementStatus(event) {
    this.activeLabel = event.detail.status;
  }
}
