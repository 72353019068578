/** @format */

import {
  bindable,
  inject,
  computedFrom,
  DOM
} from 'aurelia-framework';
import {
  TranslationService
} from 'services/translationService';

import './select-advanced.scss';

@inject(DOM.Element, TranslationService)
export class SelectAdvanced {
  @bindable items;
  @bindable enabled;
  @bindable searchable;
  @bindable isScrolling;
  @bindable onSelectionChanged;
  @bindable onActionTriggered;

  @bindable cctvDropdownStyle;
  @bindable cssClass;
  @bindable disabled;
  @bindable multiple;
  @bindable translate;
  @bindable dropdownAlign;

  @bindable name;
  @bindable label;
  @bindable customSelectedName
  @bindable placeholder;
  @bindable leadIcon;
  @bindable trailIcon;
  @bindable showDropdown;
  

  constructor(_DOMEl, _TranslationService) {
    this.element = _DOMEl;
    this.id = Math.random();
    this.items = [];
    this.enabled = true;
    this.searchable = false;
    this.multiple = false;

    this.cssClass = '';
    this.disabled = false;
    this.translate = false;
    this.dropdownAlign = 'left';

    this.showDropdown = false;
    this.filter = '';
    this.selected = null;
    this.isScrolling = false;
    this.ts = _TranslationService;

    this.viewModelRef = null;

    this.defaultSelected = {
      id: 'none',
      value: -1,
      name: '',
      label: '',
      disabled: true,
      default: true
    };

    this.name = null;
    this.label = null;
    this.placeholder = null;
    this.leadIcon = null;
    this.trailIcon = null;

    this.modes = {
      open: {
        show: true,
        class: 'dropdown-action',
        icon: 'fi-arrow_drop_down',
        action: this.onDropdownClick.bind(this)
      },
      close: {
        show: true,
        class: 'dropup-action',
        icon: 'fi-arrow_drop_up',
        action: this.onDropdownClick.bind(this)
      }
    };
    this.mode = this.modes.open;
    this.trailIcon = this.mode;

    this.onInputSelectClick = this.onInputSelectClick.bind(this);
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
  }

  attached() {
    document.addEventListener('click', this.onDocumentClick);
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  @computedFrom('selected.label')
  get selectedName() {
    if (!this.selected) {
      return;
    }

    if(this.dvr && this.selected.label) {
      return (this.translate) ? this.ts.getCap('Channel ' + this.selected.label) : 'Channel ' + this.selected.label;
    } else {
      return (this.translate) ? this.ts.getCap(this.selected.label) : this.selected.label;
    }
  }

  @computedFrom('dropdownAlign')
  get dropdownAlignment() {
    var cssClass = 'align-left';
    switch (this.dropdownAlign) {
      case 'left':
      case 'right':
      case 'center':
        cssClass = 'align-' + this.dropdownAlign;
        break
    }

    return cssClass;
  }

  multipleChanged(value) {
    this.multiple = value === true || value === 'true';
  }

  itemsChanged() {
    this.updateLabel();
  }

  onDocumentClick(e) {
    if (this.showDropdown) this.toggleDropdown(e);
  }

  onInputSelectClick(e) {
    if (this.enabled && !this.disabled) this.toggleDropdown(e);
  }

  toggleDropdown(e) {
    this.showDropdown = !this.showDropdown;
    this.filter = '';

    if (this.showDropdown) {
      if (this.viewModelRef) this.viewModelRef.inputRef.classList.add('active')
      this.changeMode(this.modes.close)
    } else {
      if (this.viewModelRef) this.viewModelRef.inputRef.classList.remove('active')
      this.changeMode(this.modes.open)
    }

    e.stopImmediatePropagation();
  }

  onDropdownClick(e) {
    e.stopImmediatePropagation();
  }

  onItemClick(e, item) {

    if (!this.multiple) {
      this.items.forEach(i => (i.selected = false));
    }

    if (item && !item.disabed) {
      item.selected = !(item.selected || false);
    }

    this.updateLabel();
    if (!this.multiple) {
      this.toggleDropdown(e);
    }
    if (this.onSelectionChanged) {
      let selected = (this.items || []).filter(x => x.selected);
      this.onSelectionChanged(this.multiple ? selected : selected[0]);
    }
  }

  onCheckboxClick(e, checkbox) {
    if (checkbox) {
      checkbox.value = !checkbox.value;

      if (this.onActionTriggered && checkbox.action) {
        this.onActionTriggered(checkbox);
      }
    }
  }

  customSelectedNameChanged() {
    this.updateLabel();
  }

  updateLabel() {
    if (this.items && this.items.length) {
      this.selected = (this.items.find(x => x.selected)) || this.items.find(x => x.default) || this.items[0];
      
      if (this.customSelectedName) {
        this.selected.label = this.customSelectedName;
        return;
      }
      
      if (this.selected.default && this.selected.id <= 0 && !this.selected.show) {
        this.selected = this.defaultSelected;
        return;
      }
      
      this.selected.label = this.selected.name;
      if (!this.selected.selected && !this.selected.default && !this.selected.hide) {
        this.selected.selected = true;
      }
      
      if (this.multiple && this.items.filter(x => x.selected).length > 1) {
        if (this.items.find(x => (x.default && this.selected.id <= 0))) {
          this.selected.label = this.ts.getCap('selected').replace('%d', this.items.filter(x => x.selected).length);
        } else {
          this.selected.label = this.ts.getCap('selected').replace('%d', this.items.filter(x => x.selected).length);
        }
      }
    }
  }

  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
  }

  onInputClick() {
    this.mode.action();
  }

  changeMode(mode) {
    this.mode = mode;
    this.trailIcon = mode;
  }
}
