/** @format */

import { bindable } from 'aurelia-framework';
import uiService from 'services/uiService';

export class SelectCountry {
  @bindable countryId;
  @bindable disabled;

  constructor() {
    this.countries = [];
    this.selected = null;
    this.uiService = uiService;
    
    this.onCountryChanged = this.onCountryChanged.bind(this);
  }

  attached() {
    this.uiService.getCountries().then(list => {
      this.countries = list;
      //default to GB for now
      this.countryId = this.countryId || 'GB';
      this.countryIdChanged(this.countryId)
    });
  }

  countryIdChanged(id) {
    //create a new list to fire select update, mutated individual array object are not observed
    let updatedList = this.countries.map(c => {
      return { ...c, selected: c.id === id };
    });
    this.countries = [].concat(updatedList);
  }

  onCountryChanged(value) {
    this.countryId = value.id;
  }
}
