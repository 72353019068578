import { bindable } from 'aurelia-framework';

import './select-advanced-icon.scss';

export class SelectAdvancedIcon {
  @bindable items;
  @bindable icon;
  @bindable disabled;
  @bindable multiple;
  @bindable translate
  @bindable onValueChanged;  
  @bindable onActionTriggered
  @bindable dropdownAlign;

  constructor() {
    this.translate = false;
  }
}
