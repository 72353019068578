/** @format */

import {
  bindable,
  computedFrom,
  observable
} from 'aurelia-framework';

import './select-input.scss';
import uiService from 'services/uiService';


export class SelectInput {

  @bindable items;
  @observable selected;
  @bindable disabled;

  @bindable name;
  @bindable label;
  @bindable autocomplete;

  @observable searchValue;
  @bindable leadIcon;
  @bindable trailIcon;
  
  @bindable filters;
  @bindable showDropdown;

  @bindable onSelectionChanged;

  @bindable snapshotNotification;

  constructor() {
    this.id = Math.random();
    this.uiService = uiService;

    this.disabled = false;
    this.snapshot = null;

    this.viewModelRef = null;
    this.name = null;
    this.label = null;
    this.searchValue = '';
    this.inputCssClass = '';
    this.leadIcon = null;
    this.trailIcon = null;
    this.modes = {
      open: {
        show: true,
        class: 'dropdown-action',
        icon: 'fi-arrow_drop_down',
        action: this.onDropdownAction.bind(this)
      },
      close: {
        show: true,
        class: 'dropup-action',
        icon: 'fi-arrow_drop_up',
        action: this.onDropdownAction.bind(this)
      },
      clear: {
        show: true,
        class: 'clear-action',
        icon: 'fi-clear',
        action: this.onCloseAction.bind(this)
      }
    };
    this.mode = this.modes.open;
    this.trailIcon = this.mode;

    this.items = [];
    this.selected = null;
    this.showDropdown = false;
    this.isScrolling = false;

    this.onCloseAction = this.onCloseAction.bind(this)
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  // GLOBAL CONTROLS

  attached() {
    document.addEventListener('click', this.onDocumentClick);
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.showDropdown) this.toggleDropdown(e);
  }

  onInputSelectClick(e) {
    e.stopImmediatePropagation();
  }

  // TEXT INPUT ELEMENT CONTROLS

  searchValueChanged() {
    if (this.searchValue.length) {
      this.changeMode(this.modes.clear)
    } else {
      if (this.showDropdown && this.modes && this.modes.close) {
        this.changeMode(this.modes.close)
      } else if (!this.showDropdown && this.modes && this.modes.open) {
        this.changeMode(this.modes.open)
      }
    }
  }

  onElementFocusBlur(e) {
    switch (e.detail.event.type) {
      case 'focus':
        if (!this.showDropdown && !this.disabled) {
          this.toggleDropdown(e);
        }
        break;
      case 'blur':
        break;
    }
  }

  onCloseAction(e) {
    this.items.forEach(i => (i.selected = false));
    this.selected = (this.items || []).find(x => x.selected);
    this.searchValue = '';
    if (this.showDropdown) {
      this.changeMode(this.modes.close)
    } else {
      this.changeMode(this.modes.open)
    }
  }

  changeMode(mode) {
    this.mode = mode;
    this.trailIcon = mode;
  }

  // DROPDOWN LIST ELEMENT CONTROLS

  @computedFrom('filters')
  get filterFields() {
    if (this.filters instanceof Array) {
      return this.filters;
    } else {
      return this.filters.split(',');
    }
  }

  itemsChanged() {
    this.selected = (this.items || []).find(x => x.selected);
  }

  selectedChanged() {
    if (this.selected && this.selected.name) {
      this.searchValue = this.selected.name;
    } else {
      this.searchValue = '';
    }

    if (this.onSelectionChanged) {
      this.onSelectionChanged(this.selected);
    }
  }

  showDropdownChanged() {
    if (this.showDropdown) {
      if (!this.searchValue.length) {
        this.changeMode(this.modes.close)
      }
    } else {
      if (!this.searchValue.length) {
        this.changeMode(this.modes.open)
      }
    }
  }

  onDropdownClick(e) {
    e.stopImmediatePropagation();
  }

  onDropdownAction(e) {
    this.toggleDropdown(e);
  }

  toggleDropdown(e) {
    this.showDropdown = !this.showDropdown;

    if (this.showDropdown) {
      this.inputCssClass = 'active';
    } else {
      this.inputCssClass = '';
    }

    // e.stopImmediatePropagation();
  }

  snapshotNotificationChanged() {
    this.snapshot = this.snapshotNotification;
  }

  onItemClick(item, e) {
    this.snapshot = item.isDeviceConnected;
    // if(this.snapshot) {
      this.items.forEach(i => (i.selected = false));
      if (item && !item.disabed) {
        item.selected = !(item.selected || false);
      }
      this.selected = (this.items || []).find(x => x.selected);
  
      this.toggleDropdown(e);
    // }
  }

  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
  }
}
