/** @format */

import { bindable, inject } from 'aurelia-framework';
import { TranslationService } from 'services/translationService';

@inject(TranslationService)
export class SelectEnum {
  @bindable items;
  @bindable trPrefix;
  @bindable selected;
  @bindable disabled;
  @bindable onValueChanged;
  @bindable label;
  @bindable searchable;
  @bindable valueAsKey;

  constructor(_TranslationService) {
    this.translations = _TranslationService;

    this._items = [];
    this.label = null;
    this.skipResfresh = false;
    this.searchable = false;

    this._onValueChanged = this._onValueChanged.bind(this);
  }

  trPrefixChanged() {
    this.refreshList();
  }

  itemsChanged(items) {
    if (items) {
      this.refreshList();
    }
  }

  selectedChanged(value) {
    if (value && !this.skipResfresh) {
      this.refreshList();

      if (this.onValueChanged) {
        this.onValueChanged(value);
      }
    }
  }

  refreshList() {
    if (!this.loaded) {
      // this._items = [];
      const prefix = this.trPrefix;
      setTimeout(() => {
        let tmpItems = [];
        for (let prop in this.items) {
          let item = this.items[prop];
          const trName =  prefix && prefix.length ? `${prefix}_${item}` : item;

          tmpItems.push({
            id: prop,
            name: this.translations.getCap(trName),
            selected:
              prop.toLowerCase() ===
              (this.selected || '').toString().toLowerCase()
          });
        }
        this._items = tmpItems;
      }, 0);
    }
  }

  capitalize(text) {
    let t = text || '';
    return t.charAt(0).toUpperCase() + t.slice(1);
  }

  _onValueChanged(value) {
    this.skipResfresh = true;
    this.selected = this.valueAsKey ? value.name : value.id;
    this.skipResfresh = false;
  }
}
